<template>
  <div class="container">
    <el-tabs type="border-card" class="main-tab">
      <el-tab-pane label="VIP包月">
        <VipPackage></VipPackage>
      </el-tab-pane>
      <el-tab-pane label="写作包"><AddPackage/></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>


import VipPackage from "./components/VipPackage.vue"
import AddPackage from "./components/AddPackage.vue"
export default {
  
  components:{
    VipPackage,
    AddPackage
  },
  data() {
    return {
       
    };
  },
  methods:{
    
  },
  computed:{
    
  },
  mounted(){
    
  }
  
};
</script>

<style lang="stylus" scoped>
.container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  .main-tab{
    border-radius: 10px;
    &>>>.el-tabs__header{
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
    }
  }
  
}
</style>