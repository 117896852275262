<template>
  <div class="wrapper" v-if="activatePackage">
    <el-row :gutter="10">
        <el-col :span="24" v-if="userVipEnd>new Date()">
            <el-alert title="本月您已经有生效的VIP包月套餐，建议您购买加量包" type="warning"></el-alert>
        </el-col>
      <el-col :span="24">
        <p class="title">套餐详情</p>
        <p v-html="activatePackage.content"></p>
      </el-col>
      <el-col
        :span="8"
        v-for="(item, k) in customer_packages"
        :key="k"
        style="margin-bottom: 20px"
      >
        <el-radio v-model="activate_key" :label="k" border>
        {{item.name}}
    </el-radio>
      </el-col>
      <el-col :span="24">
        <p class="title">购买后</p>
        <p class="content-time">
            <p ><span>生效时间：</span><span>{{ formatDate(userVipEnd,"yyyy-MM-dd hh:mm:ss")  }}</span></p>
            <p><span>到期时间：</span><span>{{  formatDate(userVipEndAfterBuy,"yyyy-MM-dd hh:mm:ss")  }}</span></p>
        </p>
      </el-col>
      <el-col :span="24">
        <p class="title">购买数量</p>
      </el-col>
      <el-col :span="12">
        <el-input-number
          v-model="order_num"
          :min="1"
          :max="24"
        ></el-input-number>
      </el-col>
      <el-col :span="12">
        <p class="price">
          <span class="price-title">价格：</span>
          <span class="price-num">¥{{ priceNum }}</span>
        </p>
      </el-col>
      <el-col :span="24">
        <el-button class="paybtn" type="success" @click="pay">支付</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get_customer_packages, create_order } from "@/api/recharge";
import { JsApiPay } from "@/api/weixin";
import { formatDate } from "@/utils/libs";
export default {
  data() {
    return {
      activate_key: 0,
      order_num: 1,
      customer_packages: [], //方案报列表
      user_packages: [], //用户的方案包
      model_type: 1, //通用写作
      package_type: 2, //vip包月
    };
  },
  methods: {
    formatDate: formatDate,
    getCustomerPackages() {
      //获取package
      get_customer_packages({
        model_type: this.model_type,
        package_type: this.package_type,
      }).then(({ data }) => {
        let list = data["list"].filter((v, i) => {
          return v.package_type == 2;
        });
        this.customer_packages = list;
        this.user_packages = data["user_vip_info"];
      });
    },
    pay() {
      //支付
      create_order({
        package_id: this.activatePackage.id,
        order_num: this.order_num,
      }).then(({ data }) => {
        JsApiPay(data).then(() => {
          this.$confirm("支付完成？", "支付结果", {
            confirmButtonText: "支付成功",
            cancelButtonText: "已取消",
          }).then(() => {
            this.$router.push({ name: "UserCenter" });
          });
        });
      });
    },
  },
  computed: {
    activatePackage() {
      //活跃的package
      return this.customer_packages[this.activate_key];
    },
    priceNum() {
      return Math.round((this.activatePackage.price * this.order_num) / 100);
    },
    userVipEnd() {
      //用户vip到期时间
      let maxEndTime = new Date();
      this.user_packages.forEach((item) => {
        const endTime = new Date(item.end_time);
        if (endTime > maxEndTime) {
          maxEndTime = endTime;
        }
      });
      return maxEndTime;
    },
    userVipEndAfterBuy() {
      let endTime = this.userVipEnd;
      let daydlt =
        this.activatePackage.expires_in * 24 * 3600 * 1000 * this.order_num;
      endTime = new Date(endTime.getTime() + daydlt);
      console.log(daydlt);
      return endTime;
    },
  },
  mounted() {
    this.getCustomerPackages();
  },
};
</script>

<style lang="stylus" scoped>
.title {
  display: block;
  box-sizing: border-box;
  border-left: 5px #409eff solid;
  padding-left: 10px;
}

.content {
  display: block;
  box-sizing: border-box;
  padding: 10px;
}

.content-time {
  margin: 0;
}

.price {
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 40px;

  .price-num {
    color: red;
  }
}

.paybtn {
  display: block;
  width: 100%;
  margin: 30px 0;
}
</style>